import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
  }) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark
    return (
			<>
        <Layout>
            <SEO title={frontmatter.title} />
            {/* <h2>{frontmatter.date}</h2> */}
            
						<div className="content">
							<div className="row">
								<div
										className="container"
										dangerouslySetInnerHTML={{ __html: html }}
								/>
							</div>
						</div>
        </Layout>
			</>
    )
  }
  export const pageQuery = graphql`
    query($slug: String!) {
      markdownRemark(frontmatter: { slug: { eq: $slug } }) {
        html
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          slug
          title
        }
      }
    }
  `